<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="部门">
                <el-cascader :options="departmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/接诊医生/联系方式" @keyup.enter.native="Search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="expand">
            <template #default="scope">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-row>
                  <div v-for="(item, i) in scope.row.mzOtherChargeses" :key="i">
                    <!-- 0直接售药 1处方 2检查治疗项 3附加收费 4代煎费用 5审方费用 6快递费 7包装费 -->
                    <el-col :span="3" :offset="1">
                      <el-form-item label="处方费" v-if="item.orderType == 1" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" :offset="1">
                      <el-form-item label="代煎费" v-if="item.orderType == 4" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" :offset="1">
                      <el-form-item label="快递费" v-if="item.orderType == 6" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" :offset="1">
                      <el-form-item label="检查治疗项" v-if="item.orderType == 2" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" :offset="1">
                      <el-form-item label="附加费" v-if="item.orderType == 3" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" :offset="1">
                      <el-form-item label="审方费" v-if="item.orderType == 5" style="margin-bottom: 0 !important">
                        <span>{{ item.money.toFixed(2) }}（元）</span>
                      </el-form-item>
                    </el-col>
                  </div>
                </el-row>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="orderNum" label="订单编号"></el-table-column>
          <el-table-column prop="doctorName" label="接诊医生" align="center"> </el-table-column>
          <el-table-column prop="doctorPhone" label="联系方式" align="center"></el-table-column>
          <el-table-column prop="departmentName" label="所属部门" align="center"></el-table-column>
          <el-table-column prop="totalPayMoney" label="订单总额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalPayMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="doctorDIstributionMoney" label="医生抽佣(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.doctorDIstributionMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="auditMoney" label="审核费用(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.auditMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="platformCommissionMoney" label="平台抽佣(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.platformCommissionMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="orgTotalMoney" label="机构入账(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.orgTotalMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="refundMoney" label="退费总额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.refundMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="refundTime" label="收费时间" align="center" width="160px">
            <template slot-scope="scope">
              {{ scope.row.refundTime | timefilters }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient.js";
import { Department } from "@/components/HospitalDomain/Department.js";
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug);
    var department = new Department(this.TokenClient, this.Services.Authorization);
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      searchForm: {
        keyWord: "",
        datepicker: "",
        departmentId: 0,
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  mounted() {
    this.getDepartment();
    this.getList();
  },
  methods: {
    handleOption(e) {
      this.searchForm.departmentId = e[e.length - 1];
      this.pageIndex = 1;
      this.getList();
    },
    getDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem("userInfo")).organizationId;
      var _this = this;
      _this.Department.GetDepartment(
        organizationId,
        function (data) {
          _this.departmentData = data.data;
        },
        function (err) {
          _this.departmentData = [];
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    },
    getList() {
      var _this = this;
      _this.tableData = [];
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      _this.OutpatientDomain.MZRefundInfo(
        item.keyWord,
        item.departmentId ? item.departmentId : 0,
        item.startTime,
        item.endTime,
        this.pageIndex,
        null,
        function (data) {
          _this.tableData = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    Search(event) {
      this.pageIndex = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
